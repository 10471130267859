<template>
  <div class="rmzl">
    <div style="width: 100%;height: 75px;display: flex;align-items: center">
      <div style="width: 1200px;border-bottom: 1px solid #fc7c40;height: 40px">
        <div class="tixing" style="width: auto;padding-right: 30px">
          {{ data.name }}
        </div>
        <span style="float: right;display: inline-block;cursor: pointer" @click="goGoodList">更多>></span>
      </div>
    </div>
    <div class="rmzl_content" style="height: auto!important;">
      <div class="rmzl_content_header" style="height: auto">
        <div style="font-size: 16px" class="zl_classify">
          <span
            @click="setSelectLabel(index)"
            style="cursor: pointer" v-for="(item,index) in data&&data.labels" :key="index"
            :style="[{'color':selectLabelId===index?'rgba(255, 106, 0, 1)':''}]">{{item.name}}</span>

        </div>
      </div>
      <div class="rmzl_content_real"  style="justify-content: flex-start;padding-left: 0;padding-right: 0">
        <div
          @click="goGoodDetail(item.id)"
          v-for="item in data&&data.labels.length>0&&data.labels[selectLabelId].objs.length>6?data.labels[selectLabelId].objs.slice(0,6):data.labels[selectLabelId].objs"
          :key="item.id"
          style="height: 153px;width: 382px;border: 1px solid #f5f5f5;cursor: pointer;margin-left: 13px">
          <img class="rmzl_image" :src="item.pic" style="height: 152px!important;width: 175px!important;"/>

          <div class="rmzl_title_price" style="width: 204px!important;">
            <div style="color: #6e6e6e" class="desc ellipse-2">
              <span style="line-height: 22px">{{item.name}}</span>
            </div>
            <div style="height: 75px">
              <div style="height: 30px;line-height: 30px;text-align: left;margin-top: 10px">
                                  <span style="color: #FDCE63">//////</span>
              </div>
              <div style="height: 45px;line-height: 45px;color: #ff6a00;font-size: 20px;text-align: left">
                <span>￥{{item.price}}</span></div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'hotPatent',
    props: {
      data: Object,
    },
    watch: {
      data: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.selectLabelId = 0;
          }
        }
      }
    },
    data() {
      return {
        selectLabelId: -1,
      }
    },
    methods: {
      setSelectLabel(id) {
        this.selectLabelId = id;
      },
      goGoodDetail(id) {
        this.$router.push({
          path: '/home/good',
          query: {
            goodId: id
          }
        })
      },
      goGoodList() {
        this.$router.push({
          path: '/home/goodsList',
          query: {
            patentType: 0
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  .rmzl_title_price .desc {
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;;
    overflow: hidden;
  }

  .rmzl_image {
    width: 148px;
    height: 148px;
    float: left;
  }

  .rmzl_title_price {
    width: 228px;
    height: 152px;
    line-height: 1;
    float: left;
    box-sizing: border-box;
    padding: 20px 15px;
  }

  .rmzl_content_real {
    padding: 20px 15px 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    max-width: 1200px;
    flex-wrap: wrap;

  }

  .rmzl_content_real > div {
    margin-bottom: 20px;
    flex-shrink: 0;
  }

  .zl_classify span {
    margin-right: 20px;
    line-height: 30px;
  }

  .rmzl_content_header {
    height: 140px;
    border-bottom: 1px solid #f5f5f5;
    box-sizing: border-box;
    padding: 20px;
  }

  .rmzl_content {
    height: 500px;
    width: 1200px;
    border: 1px solid #f5f5f5;
  }

  .rmzl {
    width: 1200px;
    margin: 0 auto;
  }

  .rmzl_content_real > div:hover {
    box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  }
</style>
